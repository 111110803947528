import { loadLanguageAsync } from "@/plugins/i18n";

const getCountryDetails = async (commit) => {
  const options = { method: "GET" };
  await fetch(
    "https://ipgeolocation.abstractapi.com/v1?api_key=" +
      process.env.VUE_APP_IP_KEY,
    options
  )
    .then((response) => response.json())
    .then(async (response) => {
      var countryData = {
        city: response.city,
        region: response.region,
        country: response.country,
        country_code: response.country_code,
        timezone: response.timezone,
        currency_code: response.currency.currency_code,
      };
      localStorage.setItem("countryData", JSON.stringify(countryData));
      if (
        countryData.country_code == "ES" ||
        countryData.country_code == "MX"
      ) {
        var lang = "es";
      } else if (countryData.country_code == "EG") {
        var lang = "ar";
      } else {
        var lang = "en";
      }
      await localStorage.setItem("lang", lang);

      const theme =
        countryData.country_code == "EG" ? "blue-theme" : "green-theme";
      document.documentElement.setAttribute("data-theme", theme);
      localStorage.setItem("theme", theme);
      var isDarkMode = countryData.country_code == "EG" ? true : false;

      await loadLanguageAsync(localStorage.getItem("lang"));

      commit("setIsDarkMode", isDarkMode);
    })
    .catch(async (err) => {
      console.log(err);
      if (localStorage.getItem("user_data")) {
        const userData = JSON.parse(localStorage.getItem("user_data"));

        await localStorage.setItem("lang", userData.user_detail.language);

        const theme = userData.user_detail.country == "eg" ? "blue-theme" : "green-theme";
        document.documentElement.setAttribute("data-theme", theme);
        localStorage.setItem("theme", theme);
        var isDarkMode = userData.user_detail.country == "eg" ? true : false;

        await loadLanguageAsync(localStorage.getItem("lang"));

        commit("setIsDarkMode", isDarkMode);
      }
    });
};

export default {
    getCountryDetails
}