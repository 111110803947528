import Vue from "vue";
import moment from "moment";
import timezone from "moment-timezone";
import store from "@/store";

Vue.filter("MMDDYYdatefilter", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MM/DD/YY");
});

Vue.filter("Currencyfilter", function (value, countryCode) {
  var currencyCodeData = {
    us: 'USD',
    mx: 'MXN',
    eg: 'EGP',
    es: 'EUR'
  };
  if (!value) return "";
  value = value.toString();
  let formattedValue = new Intl.NumberFormat('en-'+countryCode.toUpperCase(), {
    style: 'currency',
    currency: currencyCodeData?.[countryCode],
  });
  return formattedValue.format(value);
  // return (
  //   "$" +
  //   Number(value)
  //     .toFixed(2)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  // );
});

Vue.filter("CurrencySymbol", function (value, countryCode) {
  var currencyCodeData = {
    us: '$',
    mx: 'MX$',
    eg: '£',
    es: '€'
  };

  return currencyCodeData?.[countryCode];
});

Vue.filter("DateTimezoneFilter", function (value) {
  const user = store.getters["user/getUser"];
  let userTimeZone = "UTC";
  if (user && user.user_detail && user.user_detail.timezone) {
    userTimeZone = user.user_detail.timezone;
  }
  if (!value) return "";
  value = value.toString();
  const utc = timezone.utc(value).toDate();
  const localTime = timezone(utc).tz(userTimeZone).format("MM/DD/YY h:mmA");
  return localTime;
});

Vue.filter("DateZoneFilter", function (value) {
  const user = store.getters["user/getUser"];
  let userTimeZone = "UTC";
  if (user && user.user_detail && user.user_detail.timezone) {
    userTimeZone = user.user_detail.timezone;
  }
  if (!value) return "";
  value = value.toString();
  const utc = timezone.utc(value).toDate();
  const localTime = timezone(utc).tz(userTimeZone).format("MM/DD/YY");
  return localTime;
});

Vue.filter("USFormatNumber", function (value) {
  if (!value) return "";
  const formattedValue = value
    .toString()
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !formattedValue[2]
    ? formattedValue[1]
    : "(" +
        formattedValue[1] +
        ") " +
        formattedValue[2] +
        (formattedValue[3] ? "-" + formattedValue[3] : "");
});
