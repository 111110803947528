<template>
  <div id="app">
    <api-error-message></api-error-message>
    <Loading :active="loader" :is-full-page="true">
          <template>
            <img class="rd-main-loader" src="./assets/img/loader.gif" alt="Loading" />
          </template>
        </Loading>
    <div class="overlay" @click="toggleBodyClass"></div>
    <button @click="toggleBodyClass" class="btn toggle-btn">
      <img src="./assets/img/icon-close.svg" alt="" />
    </button>
    <div class="main-wrapper" v-if="showSidebar">
      <SideBar />
      <main class="wrapper">
        <div class="card">
          <NavBar />
          <div class="main-page-wrapper">
            <router-view />
          </div>
        </div>
      </main>
    </div>
    <div class="main-wrapper" v-else>
      <router-view />
    </div>
    <b-modal ref="update-modal" centered no-close-on-backdrop hide-footer title="RDL Inventory Update">
      <div class="d-block text-center">
        <h5>A new version is found. Please Update your App!!</h5>
      </div>
      <div class="d-block text-center">
        <b-button class="mt-4" variant="outline-primary" @click="accept">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SideBar,
    NavBar,
    Loading: () => import("vue-loading-overlay"),
    ApiErrorMessage: () => import("@/components/ApiErrorMessage"),
  },
  mounted() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.$refs["update-modal"].show();
      });
    }
  },
  computed: {
    ...mapGetters({
      loader: "getLoaderStatus",
    }),
    showSidebar() {
      return this.$route.name !== "Login";
    },
  },
  methods: {
    async accept() {
      await this.$refs["update-modal"].hide();
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    }
  },
};
</script>
<style lang="scss">
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
