import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { loadLanguageAsync } from "@/plugins/i18n";
import Dashboard from "../views/Dashboard.vue";
import Login from "../auth/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/car-list",
    name: "Car list",
    component: () => import("../views/CarList.vue"),
  },
  {
    path: "/part-settings",
    name: "Part Settings",
    component: () => import("../views/PartSettings.vue"),
  },
  {
    path: "/part-list",
    name: "Part list",
    component: () => import("../views/PartList.vue"),
  },
  {
    path: "/edit-vehicle/:id",
    name: "Edit Vehicle",
    component: () => import("../views/AddVehicle.vue"),
  },
  {
    path: "/car-detail/:id",
    name: "Car Detail",
    component: () => import("../views/CarDetail.vue"),
  },
  {
    path: "/bulk-parts",
    name: "Bulk Car Parts",
    component: () => import("../views/BulkParts.vue"),
  },

  {
    path: "/add-vehicle-part/:vid",
    name: "Add Vehicle Part",
    component: () => import("../views/AddParts.vue"),
  },

  {
    path: "/add-car-part/:vid",
    name: "Add Car Part",
    component: () => import("../views/AddCarParts.vue"),
  },

  {
    path: "/update-car-part/:uid",
    name: "Update Car Part",
    component: () => import("../views/AddCarParts.vue"),
  },

  {
    path: "/update-vehicle-part/:uid",
    name: "Update Vehicle Part",
    component: () => import("../views/AddParts.vue"),
  },
  {
    path: "/edit-part/:uid",
    name: "Edit Part",
    component: () => import("../views/AddParts.vue"),
  },
  {
    path: "/add-parts",
    name: "Add Parts",
    component: () => import("../views/AddParts.vue"),
  },
  {
    path: "/add-vehicle",
    name: "Add Vehicle",
    component: () => import("../views/AddVehicle.vue"),
  },
  {
    path: "/scan-barcode",
    name: "Scan Barcode",
    component: () => import("../views/ScanBarcode.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/old-inventory",
    name: "Third Party Inventory",
    component: () => import("../views/OldInventory.vue"),
  },
  {
    path: "/print-all-parts",
    name: "Print All Barcode",
    component: () => import("../views/PrintAllBarcode.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.body.classList.remove('sidebar-active');
  const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : process.env.VUE_APP_LANGUAGE;
  await loadLanguageAsync(lang);
  const publicPages = ["Login"];
  if (to.name == "Login" && store.getters["user/isLoggedIn"]) {
    next({
      path: "/dashboard",
    });
  } else if (
    !publicPages.includes(to.name) &&
    !store.getters["user/isLoggedIn"]
  ) {
    next({
      name: "Login",
    });
  } else {
    next();
  }
});

export default router;
