import http from "@/services/http.service";
import {
  PARTLIST,
  PARTLIST_DELETE_BULK,
  IMAGE,
  VIN_SEARCH,
  DIRECT_PARTS,
  PARTNAMES,
  MODELOPTION,
  YEAROPTION,
  TRIMOPTION,
  THIRD_PARTY_PART_DELETE,
  PART_LIST,
  AUCTION_PARTS,
  GET_INTERCHANGE,
  GET_P_TYPE,
  IMAGE_UPLOAD,
  SELECTED_PARTS,
  SELECTED_PARTS_UPDATE,
  GET_PARTS,
  CAR_PART_DETAILS,
  CHECKOUT_ADD_REMOVE,
  GET_QUICK_PARTS
} from "@/constants/apiUrls";
import tokenService from "./token.service";
import axios from "axios";

const getPartList = async (commit) => {
  const response = await http.get(PARTLIST);
  commit("setPartList", response.data);
};

const addParts = async (commit, payload) => {
  if(payload.recommended_saleprice){
    payload.recommended_sale_price = payload.recommended_saleprice
        .toString()
        .replace(/[^0-9/.]/g, "");
  }
  await http.post(PARTLIST, { ...payload });
};

const addAuctionParts = async (commit, payload) => {
  if(payload.recommended_sale_price){
    payload.recommended_sale_price = payload.recommended_sale_price
        .toString()
        .replace(/[^0-9/.]/g, "");
  }
  const response = await http.post(AUCTION_PARTS, { ...payload });
  commit("setAuctionPart", response.data);
};

const addDirectParts = async (commit, payload) => {
  if(payload.recommended_saleprice){
    payload.recommended_sale_price = payload.recommended_saleprice
        .toString()
        .replace(/[^0-9/.]/g, "");
  }
  await http.post(DIRECT_PARTS, { ...payload });
};

const deleteParts = async (commit, payload) => {
  await http.delete(PARTLIST + "/" + payload);
};

const deleteSelectedParts = async (commit, payload) => {
  await http.post(PARTLIST_DELETE_BULK, { ...payload });
};

const deleteCarPart = async (commit, payload) => {
  await http.delete(AUCTION_PARTS + "/" + payload);
};

const updateVehicleParts = async (commit, payload) => {
  if(payload.recommended_saleprice){
    payload.recommended_sale_price = payload.recommended_saleprice
        .toString()
        .replace(/[^0-9/.]/g, "");
  }

  const URL = payload.profile_type === "auction" ? PARTLIST : DIRECT_PARTS;
  const id =
    payload.profile_type === "auction" ? payload.id : payload.car_profile_id;
  await http.put(URL + "/" + id, { ...payload });
};

const getPartsById = async (commit, payload) => {
  const response = await http.get(PARTLIST + "/" + payload);
  response.data.data.recommended_saleprice =
    response.data.data.recommended_sale_price;
  commit("setPartById", response.data);
};

const getInterchange = async (commit, payload) => {
  const response = await http.post(GET_INTERCHANGE, { ...payload });
  commit("setInterchange", response.data);
};

const getPartNames = async (commit, query) => {
  const response = await http.get(PARTNAMES, { params: query });
  commit("setPartNames", response.data);
};

const getPart = async (commit, payload) => {
  const response = await http.get(PART_LIST + "/" + payload.id, {params: payload});
  commit("setPart", response.data);
};

const getCarPart = async (commit, payload) => {
  const response = await http.get(CAR_PART_DETAILS, {params: payload});
  commit("setCarPart", response.data);
};

const getSelectedPart = async (commit) => {
  const response = await http.get(SELECTED_PARTS);
  commit("setSelectedParts", response.data);
};

const updateSelectedParts = async (commit, payload) => {
  const response = await http.post(SELECTED_PARTS_UPDATE, payload);
};

const uploadImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_API_HOST + IMAGE,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setImageResponse", response);
};

const vinSearch = async (commit, data) => {
  const response = await http.post(VIN_SEARCH, data);
  commit("setVinSearchData", response.data);
};

const getModel = async (commit, query) => {
  const response = await http.get(MODELOPTION, { params: query });
  commit("setModel", response.data);
};

const getYear = async (commit, query) => {
  const response = await http.get(YEAROPTION, { params: query });
  commit("setYear", response.data);
};

const getTrim = async (commit, query) => {
  const response = await http.get(TRIMOPTION, { params: query });
  commit("setTrim", response.data);
};

const deleteThirdPartyParts = async (commit, payload) => {
  await http.delete(THIRD_PARTY_PART_DELETE + "/" + payload);
};

const getPType = async (commit) => {
  const response = await http.get(`${GET_P_TYPE}`);
  commit("setPType", response.data);
};

const getPartsSetting = async (commit) => {
  const response = await http.get(`${GET_PARTS}`);
  commit("setPartsSettings", response.data);
};

const uploadMediaImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_MEDIA_UPLOAD_SERVER + IMAGE_UPLOAD,
    formData
  );
  commit("setMediaImageResponse", response);
};

const getQuickParts = async (commit) => {
  const response = await http.get(`${GET_QUICK_PARTS}`);
  commit("setQuickParts", response.data);
};

const addCheckoutPart = async (commit, payload) => {
  const response = await http.post(CHECKOUT_ADD_REMOVE, payload);
  commit("setAddCheckoutPart", response.data);
};

export default {
  getPartList,
  uploadImage,
  getPart,
  getPartsSetting,
  getSelectedPart,
  updateSelectedParts,
  addParts,
  addAuctionParts,
  vinSearch,
  deleteParts,
  deleteSelectedParts,
  deleteCarPart,
  getPartsById,
  getInterchange,
  updateVehicleParts,
  addDirectParts,
  getPartNames,
  getModel,
  getYear,
  getTrim,
  deleteThirdPartyParts,
  getPType,
  uploadMediaImage,
  getCarPart,
  addCheckoutPart,
  getQuickParts
};
