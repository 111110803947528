import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import Vuelidate from "vuelidate";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/main.css";
import "./assets/css/dataTable.css";
import "./assets/css/datatable.responsive.css";
import "./assets/css/responsive.css";
import { i18n } from "./plugins/i18n";
import store from "@/store/";
import "./filters";
import "./assets/js/jquery";
import "./assets/js/jquery.dataTable";
import "./assets/js/dataTable.responsive";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { StreamBarcodeReader } from "vue-barcode-reader";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-search-select/dist/VueSearchSelect.css";
import ImageUploader from 'vue-image-upload-resize'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import './plugins/helpers';

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import wb from "./registerServiceWorker";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
Vue.prototype.$workbox = wb;
Vue.component("DateRangePicker", DateRangePicker);
Vue.use(VueSweetalert2);
Vue.component("StreamBarcodeReader", StreamBarcodeReader);
Vue.use(VueFormWizard);
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.use(ImageUploader);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
